/* Updated app.css */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.app-container {
  max-width: 1200px;
  margin-top: 100px;
  width: 95%;
  margin: 20px auto;
  padding: 40px;
  background: #f9f9f9;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  animation: fadeIn 0.5s ease-in-out;
  box-sizing: border-box;
}

h1 {
  text-align: center;
  color: #000;
  margin-bottom: 30px;
  font-size: 2rem;
  animation: slideDown 0.6s ease-in-out;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
}

input {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 25px;
  font-size: 16px;
  background-color: #fff;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

input:focus {
  border-color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  outline: none;
}

button {
  padding: 15px;
  background-color: #000;
  color: white;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

button:hover {
  background-color: #444;
  transform: translateY(-2px);
}

.logout-button {
  margin-bottom: 20px;
  align-self: flex-end;
  background-color: #000;
  color: white;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #444;
}

.error-message {
  background-color: #ffe6e6;
  color: #d60000;
  padding: 10px;
  border: 1px solid #d60000;
  border-radius: 25px;
  margin-top: 10px;
  animation: fadeIn 0.3s ease-in-out;
  text-align: center;
}

.webhook-list {
  margin-top: 30px;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.webhook-list::-webkit-scrollbar {
  width: 8px;
}

.webhook-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.webhook-item {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 25px;
  margin-bottom: 10px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.webhook-item:hover {
  background-color: #f1f1f1;
  transform: translateY(-5px);
}

.webhook-item a {
  color: #000;
  text-decoration: none;
}

.webhook-item a:hover {
  text-decoration: underline;
}

.login-container {
  max-width: 400px;
  width: 90%;
  padding: 30px;
  background: #f9f9f9;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  animation: slideUp 0.6s ease-out;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  body {
    align-items: flex-start;
    padding: 10px;
  }

  .app-container {
    margin-top: 50px;
    padding: 20px;
    border-radius: 20px;
    margin: auto;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }

  p {
    font-size: 14px;
  }
  a {
    font-size: 10px;
  }

  strong {
    font-size: 10px;
  }

  h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  button {
    padding: 12px;
    font-size: 14px;
  }

  .webhook-item {
    padding: 15px;
    border-radius: 15px;
  }

  .login-container {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
